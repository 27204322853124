import http, { Service } from './common'
const resource = 'warehousereceipt'
class WarehouseService extends Service {
  async getByShipmentId(shipmentId) {
    const response = await http.get(`${resource}/getbyshipment?shipmentId=${shipmentId}`);
    return response.status == 200 ? response.data : null;
  }
}

export default new WarehouseService(resource)
