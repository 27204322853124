<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card p-fluid">
        <h5>{{ $t('warehouseReceipt.list')}}</h5>
        <div class="field grid">
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.save')" @click="onSave($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.reset')" @click="onReset($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.back')" @click="onBack()"></Button>
            </div>
				</div>
      </div>
        <DataTable ref="dt" :value="receipts" 
            v-model:selection="selectedReceipt" dataKey="id" :showGridlines=true :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} receipts" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">{{$t('receipt.list_title')}}</h5>
							<IconField iconPosition="left">   
								<InputIcon>
									<i class="pi pi-search" />
								</InputIcon>             
								<InputText v-model="filters['global'].value" placeholder="Search..." />
							</IconField>
						</div>
					</template>
                    <Column headerStyle="width:8%; min-width:8rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openReceiptEdit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
						</template>
					</Column>
					<Column field="code" :header="$t('shipment.shipmentCode')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							{{data.shipmentDto.shipmentCode}}
						</template>
					</Column>
					<Column field="code" :header="$t('shipment.referenceCode')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('shipmentDto.referenceCode')}}</span>
							{{data.shipmentDto.referenceCode}}
						</template>
					</Column>
					<Column field="code" :header="$t('warehouseReceipt.deliveryAgent')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.deliveryAgent')}}</span>
							{{data.deliveryAgent}}
						</template>
					</Column>
					<Column field="courierName" :header="$t('warehouseReceipt.courierName')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.courierName')}}</span>
							{{data.courierName}}
						</template>
					</Column>
					<Column field="courierPhone" :header="$t('warehouseReceipt.courierPhone')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.courierPhone')}}</span>
							{{data.courierPhone}}
						</template>
					</Column>
					<Column field="recipient" :header="$t('warehouseReceipt.recipient')"  headerStyle="width:8%; min-width:4rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.recipient')}}</span>
							{{data.recipient}}
						</template>
					</Column>
					<Column field="packageNumber" :header="$t('warehouseReceipt.packageNumber')" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.packageNumber')}}</span>
							{{data.packageNumber}}
						</template>
					</Column>
					<Column field="note" :header="$t('warehouseReceipt.note')" :sortable="true">
						<template #body="{ data }">
							<span class="p-column-title">{{$t('warehouseReceipt.note')}}</span>
							{{data.note}}
						</template>
					</Column>
				</DataTable>
    </div>
  </div>

</template>
<script>
import WarehouseService from '@/service/WarehouseService';
export default {
  components: {},
  data() {
    return {
      receipts: [],
      selectedReceipt: null,
      filters: {
        global: {
          value: null
        }
      }
     
    }
  },
  async mounted() {
   const response = await WarehouseService.getAll();
   this.receipts = response;
  },
  methods: {
    openReceiptEdit(entity) {
      this.$router.push({ name: 'warehouse_receipt_edit', params: { shipmentId: entity.shipmentDto.id } });
    },
    confirmDeleteEntity(entity) {
      console.log(entity);
    }
  }
}
</script>
